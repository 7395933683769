import { ExploreRounded } from '@material-ui/icons';
export const educationData = [
    {
        id: 1,
        institution: 'eBuds NFT collection is stored on the MultiversX blockchain (former Elrond) and accessible to anyone with an Elrond wallet.',
        course: ' eBuds NFT collection consists of 1000 NFT digital artworks.',
        startYear: 'February,',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'The $EBUD token is a digital asset that can be used to purchase goods and services on the MultiversX. ',
        course: 'The $EBUD Token was created to provide utility for the eBuds NFT project, ',
        startYear: 'June',
        endYear: '2022'
    },
    {
        id: 3,
        institution: 'The $EBUD token is listed on Jexchange.io. ',
        course: 'The $EBUD Token was verified by Elrond team. ',
        startYear: 'January',
        endYear: '2023'
    },
    {
        id: 3,
        institution: 'The EBUD token has been listed on  Exrond.com. ',
        course: 'We have initiated the development of our initial liquidity pool.',
        startYear: 'February',
        endYear: '2023'
    },

]